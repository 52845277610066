<template>
  <div class="design-system">
    <h1>Design Hub Design System for Custom Components</h1>

    <section>
      <h2>DetailLibraryItem</h2>
    </section>
  </div>
</template>

<script>
export default {
  name: 'DesignSystem',

  data: () => ({
    styles: []
  }),

  mounted () {
    fetch('http://localhost:3000/styles')
      .then(response => {
        return response.json()
      })
      .then(styles => {
        this.styles = styles
      })
  }
}
</script>

<style lang="scss" scoped>
.design-system {
  section {
    display: flex;
  }
}
</style>
